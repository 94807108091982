header{
	width:100%; 
  display: flex;
  justify-content:center;
  align-items:center;
  font-size:12px;
  
}


.Logo-Company {
  margin-top: 25px;
  margin-bottom: 35px;
  width: 10%;
  height: auto;
  display: block;
  transition: transform 1.4s ease;
  z-index: 1; /* Assurez-vous que le logo est au-dessus des autres éléments */
}
.Logo-Company:hover{
  transform: scale(1.2);
}
.Logo-Company:not(:hover){
  transform: scale(1);
}
.hamburger{
  background:none;
  position:absolute;
  top:0;
  right:0;
  line-height:45px;
  padding:5px 15px 0px 15px;
  color:#999;
  border:0;
  font-size:2.6em;
  font-weight:bold;
  cursor:pointer;
  outline:none;
  z-index:10000000000000;
  display: none;
}
/* <button className="hamburger" onClick={toggleMenu}>&#9776;</button> pour téléphone*/
.hamburger--menu {
  background: none;
  position: absolute;
  top: 0;
  right: 15px;
  line-height: 45px;
  padding: 5px 15px 0px 15px;
  border: 2px solid blueviolet; /* Ajout de la bordure de 2px en noir */
  color: black;
  background-color: rgb(249, 250, 220);
  font-size: 2.6em;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  z-index: 10000000000000;
}
.hamburger--menu:hover{
  color: blueviolet;
  background-color: rgb(249, 250, 220);
}
.cross{
  background:none;
  position:fixed;
  top:0px;
  right:0;
  padding:7px 15px 0px 15px;
  color:#999;
  border:0;
  font-size:6em;
  line-height:65px;
  font-weight:bold;
  cursor:pointer;
  outline:none;
  z-index:10000000000000;
}
.menu{
    z-index:1000000; 
    font-weight:bold; 
    font-size:6em; 
    width:100%;
    height: 100%; 
    background:black;  
    position:fixed; 
    text-align:center; 
    font-size:18px;
    top: 0px; /* Ajout de cette ligne */
    opacity: 0.7;
  }
  .menu ul {
    margin: 0; 
    padding: 0; 
    list-style-type: none; 
    list-style-image: none;
   position: fixed;
   top: 30%;
    width: 100%;
  }
  .menu li {
    display: block;   
    padding:15px 0 15px 0; 
    font-size: 3em;
    border-bottom:white 1px solid;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  .menu li:hover{
    display: block;    
    background:#ffffff; 
    padding:15px 0 15px 0; 
    border-bottom:#dddddd 1px solid;
    transform: scale(1.3);
  }
  .menu ul li a { 
    text-decoration:none;  
    margin: 0px; 
    color:white;
  }
  .menu ul li a:hover {  
    color: #666; 
    text-decoration:none;
  }
  .menu a {
    text-decoration: none;
    color: white;
  
  }
  
  .menu a:hover {
    text-decoration: none;
    color: #666;

  }
  
  .glyphicon-home{
    color:white; 
    font-size:1.5em; 
    margin-top:5px; 
    margin:0 auto;
  }
  a{
    color:#336699;
  }

  @keyframes slideInFromRight {
    from {
      transform: translateX(200%);
      opacity: 0;
    }
    to {
      transform: translateX(0%);
      opacity: 0.7;
    }
  }

  
  .menuVisible {
    animation: slideInFromRight 1.5s forwards cubic-bezier(0.32, 0.64, 0.45, 1);
  }


  @media (max-width: 998px) {
    .Logo-Company {
      width: 20%;
    }
  }
@media (max-width: 636px) {
  .Logo-Company {
    width: 20%;
  }
}


  @media (max-width: 440px) {

    .Logo-Company {
      width: 30%;
    }

    
    .hamburger {
      display: flex;
    }
    .hamburger--menu {
      display: none;
    }
    .menu li {
      font-size: 2em;
    }
  }