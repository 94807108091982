/* Déclaration de la police Urbanist */
@font-face {
    font-family: 'Urbanist';
    src: url('./Assets/Fonts/Urbanist-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: 'Urbanist';
    src: url('./Assets/Fonts/Urbanist-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: 100 900;
    font-style: italic;
}

/* Utilisation de la police Urbanist */
body {
    font-family: 'Urbanist', sans-serif;
}
