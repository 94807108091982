/* Container for the works section */
.works--container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between items */
    justify-content: space-between; /* Distribute space between items */
}

/* Individual project item */
.project {
    flex: 1 1 30%; /* Allow items to take up to 30% of the container's width */
    max-width: 30%; /* Limit the maximum width to 30% */
    box-sizing: border-box; /* Include padding and border in the element's total width */
    margin-bottom: 20px; /* Space at the bottom for items that wrap to the next line */
    padding: 10px; /* Add padding inside the project item */
    border: 1px solid #ccc; /* Add a border around the project item */
    border-radius: 25px; 
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}
@media (max-width: 702px) {
/* Container for the works section */
.works--container {
    display: flex;
    flex-direction: column; /* Arrange items in a single column */
    gap: 10px; /* Space between items */
}

/* Individual project item */
.project {
    flex: 1 1 100%; /* Allow items to take up to 100% of the container's width */
    min-width: 90%; /* Limit the maximum width to 100% */
    box-sizing: border-box; /* Include padding and border in the element's total width */
    margin-bottom: 20px; /* Space at the bottom for items that wrap to the next line */
    padding: 10px; /* Add padding inside the project item */
    border: 1px solid #ccc; /* Add a border around the project item */
    border-radius: 25px; 
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.tags {
font-size: 1.8em; /* Set the font size */
}
}



/* Project title */
.works--title--project {
    font-size: 1.5em; /* Increase the font size */
    margin-bottom: 10px; /* Space below the title */
}

/* Project image */
.works--picture {
    width: 100%; /* Make the image take up the full width of the project item */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 5px; /* Round the corners of the image */
    margin-bottom: 10px; /* Space below the image */
}

/* Project content text */
.works-content-text {
    font-size: 1em; /* Set the font size */
    margin-bottom: 10px; /* Space below the content text */
}

/* Tags container */
.tags {
    display: flex;
    gap: 10px; /* Space between tags */
    flex-wrap: wrap; /* Allow tags to wrap to the next line if necessary */
}

/* Individual tag */
.tag {
    background-color: blueviolet; /* Background color for the tag */
    color: white; /* Text color for the tag */
    padding: 5px 10px; /* Padding inside the tag */
    border-radius: 5px; /* Round the corners of the tag */
    font-size: 0.9em; /* Set the font size */
}

/* Container for the works section */
.works--container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between items */
    justify-content: space-between; /* Distribute space between items */
}

@media (max-width: 1879px) {
    .works-content-text {
      font-size: 0.7em; /* Limit the maximum width to 100% */
    }
}


/* Individual project item */
.project {
    flex: 1 1 30%; /* Allow items to take up to 30% of the container's width */
    max-width: 30%; /* Limit the maximum width to 30% */
    box-sizing: border-box; /* Include padding and border in the element's total width */
    margin-bottom: 20px; /* Space at the bottom for items that wrap to the next line */
    padding: 10px; /* Add padding inside the project item */ /* Add a border around the project item */
    border-radius: 25px;
    height: 350px; /* Light background color for the project item */
    justify-content: flex-start;
}

/* Project title */
.works--title--project {
    font-size: 1.5em; /* Increase the font size */
    margin-bottom: 10px; /* Space below the title */
}

/* Project image */
.works--picture-container {
    width: 100%; /* Make the image take up the full width of the project item */
    height: 135px; /* Maintain the aspect ratio of the image */
    border-radius: 25px; 
    overflow: hidden; /* Hide any overflow content */
    background-color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.works--picture {
    max-width: 100%;
    width: 50%; /* Limite la largeur maximale à 100% de son conteneur parent */
    height: 80px; /* Maintient le ratio d'aspect de l'image */
margin-bottom: 0;

}

/* Project content text */
.works-content-text {
    font-size: 2.3em; /* Set the font size */
    margin-bottom: 10px;
    margin-top: 15px; /* Space below the content text */
}

@media (max-width: 1879px) {
    .works-content-text {
        font-size: 1.7em; /* Reduce the font size */
    }
}
@media (max-width: 1408px) {
    .works-content-text {
        font-size: 1.3em; /* Reduce the font size */
    }
}
@media (max-width: 1094px) {
    .works-content-text {
        font-size: 1em; /* Reduce the font size */
    }
}
@media (max-width: 889px) {
    .works-content-text {
        font-size: 0.8em; /* Reduce the font size */
    }
    .tag {
        font-size: 0.7em; /* Reduce the font size */
    }
}
    @media (max-width: 702px) {
        .works-content-text {
            font-size: 1.9em; /* Limit the maximum width to 100% */
          }
    }


/* Tags container */
.tags {
    display: flex;
    gap: 10px; /* Space between tags */
    flex-wrap: wrap; /* Allow tags to wrap to the next line if necessary */
}

/* Individual tag */
.tag {
    background-color: blueviolet; /* Background color for the tag */
    color: white; /* Text color for the tag */
    padding: 5px 10px; /* Padding inside the tag */
    border-radius: 5px; /* Round the corners of the tag */
    font-size: 0.9em; /* Set the font size */
}
@media (max-width: 889px) {
    .tag {
        font-size: 0.7em; /* Reduce the font size */
    }
}
@media (max-width: 790px) {
    .tag {
        font-size: 0.5em; /* Reduce the font size */
    }
}

.works-icon {
    font-size: 2em;
    color: black;
    margin-top: 35px;
}

/* Show more button */
.show-more-button {
    display: block;
    margin: 20px auto; /* Center the button */
    padding: 10px 20px; /* Add padding inside the button */
    font-size: 1em; /* Set the font size */
    background-color: blueviolet; /* Background color for the button */
    color: white; /* Text color for the button */
    border: none; /* Remove the border */
    border-radius: 5px; /* Round the corners of the button */
    cursor: pointer; /* Change the cursor to a pointer */
}

.show-more-button:hover {
    background-color: darkviolet; /* Change background color on hover */
}


.works-active {
    height: fit-content;
}
@media (max-width: 1200px) {
    .works-active {
        width: 80%;
        max-width: 80%;
    }
}

/* Container for the dropdown images */
/* Container for the dropdown images */
.dropdown-pics-container {
    display: flex;
    justify-content: center; /* Center the images horizontally */
    align-items: center; /* Center the images vertically */
    gap: 10px; /* Space between the images */
    margin-top: 10px; /* Space above the container */
   }


.dropdown-pics {
    max-width: 45%; /* Limit the maximum width to 45% of the container */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 5px; /* Round the corners of the image */
 }



/* Hidden projects */
.project.hidden {
    display: none;
}

/* Dropdown content */
.dropdown-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.hidden {
    display: none;
  }
  
  .visible {
    display: block; /* Let the content flow normally as a block */
    width: 100%; /* Ensure content takes the full width */
    text-align: center; /* Center text inside the block */
  }
  

/* Expanded dropdown content */
.project .dropdown-content {
    max-height: 100%; 
}
#works-drop-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ensure the container has a defined height */
    text-align: center; /* Center the text horizontally */
    font-size: 1.3em;
}

.github_link_logo {

    text-align: center; /* Center the text horizontally */
    font-size: 3em;
    padding-top: 30px;
    color :  blueviolet;
}
.github_link_logo:hover {
    color: darkgoldenrod;
}
